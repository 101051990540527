/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "src/components/seo"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"
import Header from "src/components/Header"
import HeroStandard from "src/components/HeroStandard"
import Footer from "src/components/Footer"

const NotFoundPage = props => {
  const {
    wpgraphql: {
      acfGlobalOptions: globalOptions
    }
  } = useStaticQuery(graphql`
      query NotFoundPageData {
        wpgraphql {
          acfGlobalOptions {
            notFoundTitle
            notFoundBody
          }
        }
      }
  `)

  return (
    <Fragment>
      <SEO title={globalOptions.notFoundTitle} />

      <SkipNavLink
        sx={{
          variant: "link.skipNav",
        }}
      />

      <Header path={props.location.pathname} />

      <main>
        <SkipNavContent />

      <HeroStandard
        title={globalOptions.notFoundTitle}
        breadcrumbs={[{ title: "Home", link: "/" }]}
      >
        <div 
        sx={{
          'p': {
            variant: "text.intro",
            mb: 5,
          },
          'a': {
            variant: "styles.a",
          }
        }}
        dangerouslySetInnerHTML={{
          __html: globalOptions.notFoundBody,
        }} />
      </HeroStandard>
      </main>

      <Footer />
    </Fragment>
  )
}

export default NotFoundPage
